exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-ca-js": () => import("./../../../src/pages/about.en-CA.js" /* webpackChunkName: "component---src-pages-about-en-ca-js" */),
  "component---src-pages-about-fr-ca-js": () => import("./../../../src/pages/about.fr-CA.js" /* webpackChunkName: "component---src-pages-about-fr-ca-js" */),
  "component---src-pages-index-en-ca-js": () => import("./../../../src/pages/index.en-CA.js" /* webpackChunkName: "component---src-pages-index-en-ca-js" */),
  "component---src-pages-index-fr-ca-js": () => import("./../../../src/pages/index.fr-CA.js" /* webpackChunkName: "component---src-pages-index-fr-ca-js" */),
  "component---src-pages-learn-en-ca-js": () => import("./../../../src/pages/learn.en-CA.js" /* webpackChunkName: "component---src-pages-learn-en-ca-js" */),
  "component---src-pages-learn-fr-ca-js": () => import("./../../../src/pages/learn.fr-CA.js" /* webpackChunkName: "component---src-pages-learn-fr-ca-js" */),
  "component---src-pages-personal-finance-en-ca-js": () => import("./../../../src/pages/personal-finance.en-CA.js" /* webpackChunkName: "component---src-pages-personal-finance-en-ca-js" */),
  "component---src-pages-personal-finance-fr-ca-js": () => import("./../../../src/pages/personal-finance.fr-CA.js" /* webpackChunkName: "component---src-pages-personal-finance-fr-ca-js" */),
  "component---src-pages-saving-for-school-en-ca-js": () => import("./../../../src/pages/saving-for-school.en-CA.js" /* webpackChunkName: "component---src-pages-saving-for-school-en-ca-js" */),
  "component---src-pages-saving-for-school-fr-ca-js": () => import("./../../../src/pages/saving-for-school.fr-CA.js" /* webpackChunkName: "component---src-pages-saving-for-school-fr-ca-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tool-education-savings-calculator-en-ca-js": () => import("./../../../src/pages/tool/education-savings-calculator.en-CA.js" /* webpackChunkName: "component---src-pages-tool-education-savings-calculator-en-ca-js" */),
  "component---src-templates-learn-post-js": () => import("./../../../src/templates/learn-post.js" /* webpackChunkName: "component---src-templates-learn-post-js" */)
}

